import { applyMiddleware, createStore, compose, } from "redux";
import reducer from "../reducer/reducerIndex";
import thunk from "redux-thunk";

const composeEnhancers =
    (typeof window !== "undefined" &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)))

export default store
